import React from 'react'
import { withTheme } from '../Helpers/themes'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import './favorite-books-section.scss'
import { MDBCard, MDBCardBody } from 'mdbreact'

const FavoriteBooksSection = props => {
  const { allContentfulBookTemplate } = useStaticQuery(graphql`
    query {
      allContentfulBookTemplate(sort: { order: DESC, fields: reviewDate }) {
        edges {
          node {
            reviewDate
            rating
            reviewText {
              content {
                content {
                  value
                }
              }
            }
            amazonUrl
            title
            authorsLine
            bookCover {
              fixed(width: 150) {
                ...GatsbyContentfulFixed_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  const getRatingArray = rating => {
    let ratingArray = []
    for (rating; rating > 0; rating--) {
      if (!Number.isInteger(rating)) {
        ratingArray.push(0.5)
        rating = Math.floor(rating)
      }
      ratingArray.push(1)
    }
    return ratingArray.reverse()
  }
  const getDay = reviewDateString => {
    const currentDate = new Date()
    const reviewDate = new Date(reviewDateString)
    return reviewDate > currentDate
      ? '...'
      : reviewDate.getDate() < 10
      ? '0' + reviewDate.getDate()
      : reviewDate.getDate()
  }
  const monthNames = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ]
  const getMonth = reviewDateString => {
    const currentDate = new Date()
    const reviewDate = new Date(reviewDateString)
    return reviewDate > currentDate ? '' : monthNames[reviewDate.getMonth()]
  }
  const getYear = reviewDateString => {
    const reviewDate = new Date(reviewDateString)
    return reviewDate.getFullYear()
  }
  const common = allContentfulBookTemplate.edges
  const yearBreakIndexes = []
  common.slice(0, common.length - 1).map((entry, index) => {
    if (index == 0) {
      yearBreakIndexes.push(index)
    }
    const currentEntryYear = getYear(entry.node.reviewDate)
    const nextEntryYear = getYear(common[index + 1].node.reviewDate)
    if (currentEntryYear != nextEntryYear) {
      yearBreakIndexes.push(index + 1)
    }
  })

  const generateCard = entry => {
    return (
      <div className="row card-row">
        <div className="col-12">
          <MDBCard
            className="book-card"
            style={
              getMonth(entry.node.reviewDate) == ''
                ? { backgroundColor: '#DCE7EC' }
                : null
            }
          >
            <div
              className="book-review-date"
              aria-hidden="true"
              style={props.theme.secondaryBackgroundColor}
            >
              <span className="review-day">
                <span>{getDay(entry.node.reviewDate)}</span>
              </span>
              <span className="review-month">
                <span>{getMonth(entry.node.reviewDate)}</span>
              </span>
            </div>
            <MDBCardBody>
              <a rel="noopener" href={entry.node.amazonUrl} target="_blank">
                <Img
                  className="book-cover"
                  alt="favorite-book"
                  fixed={entry.node.bookCover.fixed}
                />
              </a>
              <hr />
              <div className="book-details">
                <h4 className="book-title">{entry.node.title}</h4>
                <p className="book-authors">{entry.node.authorsLine}</p>
                <div className="book-rating">
                  {getRatingArray(entry.node.rating).map((entry, index) =>
                    entry == 1 ? (
                      <i key={index} className="fas fa-star"></i>
                    ) : (
                      <i key={index} className="fas fa-star-half"></i>
                    )
                  )}
                </div>
                <p className="book-review">
                  {entry.node.reviewText.content[0].content[0].value}
                </p>
              </div>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    )
  }

  return (
    <div className="pt-5" style={props.theme.tertiaryBackgroundColor}>
      <div className="container row-container">
        {common.map((entry, index) => {
          if (yearBreakIndexes.includes(index)) {
            return (
              <div className="index-container" key={index}>
                <MDBCard
                  className="year-card"
                  style={props.theme.primaryBackgroundColor}
                >
                  <MDBCardBody>
                    <h1>- {getYear(entry.node.reviewDate)} -</h1>
                  </MDBCardBody>
                </MDBCard>
                {generateCard(entry, index)}
              </div>
            )
          } else {
            return (
              <div className="index-container" key={index}>
                {generateCard(entry, index)}
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default withTheme(FavoriteBooksSection)
